import React from 'react';
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import SIZES from '../utils/sizes'
import COLORS from '../utils/colors'



const StyledPortfolioCard = styled.article`

    height:306px;
    width:193px;
   
    position: relative;
    border: solid 1px #EEE;
    border-radius:6px;

    .portfolio-image{
        height:100% !important;
        width:100% !important;
        border-radius:4px;
       
    }

    .portfolio-card-info{

        opacity:1;
        position:absolute;
        z-index:1;

        top:50%;
        left:50%;
        transform: translateX(-50%) translateY(-50%);

        background-color:${COLORS.secundary};
        padding:8px;
        height:136px;
        width:136px;
        border-radius:50%;
        line-height:1.6rem;

        h4{
            color:${COLORS.primary};
            font-size:1.2rem;
            position:absolute;
            top:50%;
            left:50%;
            transform: translateX(-50%) translateY(-50%);
            text-align:center;

        }

        span{
            font-size:0.6rem;
        
            position:absolute;
            bottom:0;
            left:50%;
            transform: translateX(-50%) translateY(-25%);
            text-align:center;
        }

        transition: opacity 0.4s ease;
    }

    .portfolio-card-info:hover{
        opacity:0;
    }

 

   

`


const IndexPortfolioItem = ({ number, title, category, slug, src, alt}) => {

    return (

        <StyledPortfolioCard>
            <Link className="lien-portfolio" to={`/portfolio/${slug}`}> 
                <div className="portfolio-card-info">
                    <h4>{title}</h4>
                    <span>{category}</span>
                </div>
                
                <GatsbyImage className="portfolio-image" image={src} alt={alt} objectFit="cover" />

            </Link> 
        </StyledPortfolioCard>

        
    );
};

IndexPortfolioItem.propTypes = {
    number: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
}

export default IndexPortfolioItem;