import React from 'react';
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { graphql, useStaticQuery } from 'gatsby';
import SIZES from '../utils/sizes';
import styled from "styled-components"
import IndexPortfolioItem from '../components/IndexPortfolioItem'

const PortfolioSection = styled.section`

    display: inline-flex;
    flex-wrap: wrap;
    gap:24px;
    margin-top: 72px;

    @media (max-width: ${SIZES.thirdBreakPoint}){
        justify-content:center;
    }
    
`

const getProjects = graphql`
    query{
        allContentfulPortfolioItem (

        sort:{
            fields:number,
            order:DESC
          })
          {
              edges{
                node{
                    id
                    number
                    title
                    shortTitle
                    slug
                    category
                    featImage {
                        title
                        gatsbyImageData
                      }
                    }
                
                }
        }
       
      }
`




const PortfolioPage = () => {
    const data = useStaticQuery(getProjects)
    let numero = 0;

    return (
        <Layout theme="portfolio">
            <SEO title="Portfolio"/>
            <h1 className="page-main-title"><span className="thin">Espace</span> Portfolio</h1>
            <h3 className="sous-titre">Quelques exemples de projets.</h3>
         
            <PortfolioSection>
                {
                    data.allContentfulPortfolioItem.edges.map((edge) => {
                    numero ++
                    return (

                        <IndexPortfolioItem
                            key={edge.node.id}
                            number={numero}
                            title={edge.node.shortTitle}
                            category={edge.node.category}
                            slug={edge.node.slug}
                            src={edge.node.featImage.gatsbyImageData}
                            alt={edge.node.featImage.title}
                        />
                    )
                })}
            </PortfolioSection>
        </Layout>
    );
};

export default PortfolioPage;